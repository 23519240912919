<template>
  <div class="items__inner">
    <div v-if="isEmpty">{{ $t('global.noResults') }}</div>
    <el-row
      v-for="(item, index) in items"
      :key="index"
      type="flex"
      class="items__row"
      justify="space-between"
      align="middle">
      <div class="items__label">
        <span v-if="item.label">{{ item.label }}</span>
        <span v-if="item.title && item.title.rendered">{{ item.title.rendered }}</span>
      </div>
      <a
        class="button button--primary items__button"
        target="_blank"
        :href="item.file">
        {{ $t('global.download') }}
      </a>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'DownloadFileRow',
  props: [
    'items',
    'isEmpty',
  ],
};
</script>

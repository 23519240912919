const orderbyItems = [
  {
    value: 'date-desc',
    label: 'dateDesc',
    params: {
      order: 'desc',
      orderby: 'date',
    },
  },
  {
    value: 'date-asc',
    label: 'dateAsc',
    params: {
      order: 'asc',
      orderby: 'date',
    },
  },
  {
    value: 'title-asc',
    label: 'titleAsc',
    params: {
      order: 'asc',
      orderby: 'title',
    },
  },
  {
    value: 'title-desc',
    label: 'titleDesc',
    params: {
      order: 'desc',
      orderby: 'title',
    },
  },
];

export default orderbyItems;
